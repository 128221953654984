import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Col,
  Button,
  ButtonGroup,
  Container,
  Row,
  ToggleButton,
  Modal,
  ToggleButtonGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FaTrash } from "react-icons/fa";

import { Icon } from "@iconify/react";
import pailOutline from "@iconify/icons-mdi/pail-outline";
import pailOffOutline from "@iconify/icons-mdi/pail-off-outline";
import eyeIcon from "@iconify/icons-mdi/eye";
import eyeOff from "@iconify/icons-mdi/eye-off";
import cameraIcon from "@iconify/icons-mdi/camera";
import ledVariantOff from "@iconify/icons-mdi/led-variant-off";
import ledOn from "@iconify/icons-mdi/led-on";
import lockOpenVariant from "@iconify/icons-mdi/lock-open-variant";
import lockIcon from "@iconify/icons-mdi/lock";
import cameraOff from "@iconify/icons-mdi/camera-off";
import volumeVibrate from "@iconify/icons-mdi/volume-vibrate";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

import axios from "axios";
import { TRAPHISTORY_SERVER } from "../../Config";
import { SETTING_SERVER } from "../../Config";
import { DectCanvas } from "./DectCanvas";
import { getWs, sendJsonByWebSocket } from "../../../common";
import { eventDescriptions } from "../../DeviceEvents";

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

export const CameraControl = (props) => {
  const [traphistories, setTraphistories] = useState([]);
  const [selectedTraps, setSelectedTraps] = useState([]);

  /* pagination handling */
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [cursor, setCursor] = useState(0);
  const [firstElCurrentPage, setFirstElCurrentPage] = useState(0);
  const [lastElCurrentPage, setLastElCurrentPage] = useState(0);

  const getNextPage = (page) => {
    let user = JSON.parse(window.localStorage.getItem("user"));
    axios
      .post(`${TRAPHISTORY_SERVER}/getfromdeviceid`, {
        deviceId: props.deviceId,
        userId: user.id,
        cursor: cursor,
        step: 1,
      })
      .then((data) => {
        if (data.data.success) {
          let temp = data.data.traphistories;
          let tempAutoCount = 0; //count of auto trapped
          let tmpImages = [];
          temp.forEach((item) => {
            if (item.event === "auto") {
              tempAutoCount++;
            }

            // append image to images
            if (item.filename) {
              tmpImages.push({
                original: "/" + item.filename,
              });
            }
          });
          setGalleryImages(tmpImages);
          props.updateAutoCount(tempAutoCount);
          let stIdx = (page - 1) * 10;
          let temp2 = traphistories;
          temp2.splice(stIdx, 10, ...temp);
          setTraphistories(temp2);
          setCursor(data.data.cursor);
          setTotalRecords(data.data.totalRecords);
          setCurrentPage(page);
          setFirstElCurrentPage(temp[0].id);
          setLastElCurrentPage(temp[temp.length - 1].id);
        }
      });
  }

  const getPrevPage = (page) => {
    let user = JSON.parse(window.localStorage.getItem("user"));
    axios
      .post(`${TRAPHISTORY_SERVER}/getfromdeviceid`, {
        deviceId: props.deviceId,
        userId: user.id,
        cursor: firstElCurrentPage,
        step: -1,
      })
      .then((data) => {
        if (data.data.success) {
          let temp = data.data.traphistories;
          let tempAutoCount = 0; //count of auto trapped
          let tmpImages = [];
          temp.forEach((item) => {
            if (item.event === "auto") {
              tempAutoCount++;
            }

            // append image to images
            if (item.filename) {
              tmpImages.push({
                original: "/" + item.filename,
              });
            }
          });
          setGalleryImages(tmpImages);
          props.updateAutoCount(tempAutoCount);
          let stIdx = (page - 1) * 10;
          let temp2 = traphistories;
          temp2.splice(stIdx, 10, ...temp);
          setTraphistories(temp2);
          setCursor(data.data.cursor);
          setTotalRecords(data.data.totalRecords);
          setCurrentPage(page);
          setFirstElCurrentPage(temp[0].id);
          setLastElCurrentPage(temp[temp.length - 1].id);
        }
      });
  }

  const getLastPage = (page) => {
    let user = JSON.parse(window.localStorage.getItem("user"));
    axios
      .post(`${TRAPHISTORY_SERVER}/getfromdeviceid`, {
        deviceId: props.deviceId,
        userId: user.id,
        cursor: firstElCurrentPage,
        step: -1,
        getLastPage: true,
      })
      .then((data) => {
        if (data.data.success) {
          let temp = data.data.traphistories;
          let tempAutoCount = 0; //count of auto trapped
          let tmpImages = [];
          temp.forEach((item) => {
            if (item.event === "auto") {
              tempAutoCount++;
            }

            // append image to images
            if (item.filename) {
              tmpImages.push({
                original: "/" + item.filename,
              });
            }
          });
          setGalleryImages(tmpImages);
          props.updateAutoCount(tempAutoCount);
          let stIdx = (page - 1) * 10;
          let temp2 = traphistories;
          temp2.splice(stIdx, 10, ...temp);
          setTraphistories(temp2);
          setCursor(data.data.cursor);
          setTotalRecords(data.data.totalRecords);
          setCurrentPage(page);
          setFirstElCurrentPage(temp[0].id);
          setLastElCurrentPage(temp[temp.length - 1].id);
        }
      });
  }

  const handlePageChange = (page) => {
    let pageDelta = page - currentPage;
    if (page === totalPages) {
      getLastPage(page);
    } else if (page === 1) {
      let tempAutoCount = 0; //count of auto trapped
      let tmpImages = [];
      for (let i = 0; i < 10; i++) {
        let item = traphistories[i];
        if (item.event === "auto") {
          tempAutoCount++;
        }

        // append image to images
        if (item.filename) {
          tmpImages.push({
            original: "/" + item.filename,
          });
        }
      };
      setGalleryImages(tmpImages);
      props.updateAutoCount(tempAutoCount);
      setCurrentPage(1);
      setFirstElCurrentPage(traphistories[0].id);
      setLastElCurrentPage(traphistories[9].id);
      setCursor(traphistories[9].id);
    } else {
      if (pageDelta === 1) {
        getNextPage(page);
      } else if (pageDelta === -1) {
        getPrevPage(page);
      }
    }
  };

  const pnoptions = {
    page: currentPage,
    sizePerPage: 10,
    totalSize: totalRecords,
    onPageChange: handlePageChange,
    hideSizePerPage: true,
    paginationSize: 1,
  };

  const [isCameraOn, setIsCameraOn] = useState(false);
  const [isTrapOpen, setIsTrapOpen] = useState(false);
  const [isBucketOn, setIsBucketOn] = useState(false);
  const [isLEDOn, setIsLEDOn] = useState(false);
  const [streamImage, setStreamImage] = useState(null);
  const [isShowPicture, setIsShowPicture] = useState(false);

  const [showImageModal, setShowImageModal] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const [showingImageDate, setShowingImageDate] = useState("");
  const [showingImageEvent, setShowingImageEvent] = useState("");
  const [userTimezone, setuserTimezone] = useState("America/Halifax");
  const [galleryImages, setGalleryImages] = useState([]);
  const [imageGalleryIndex, setImageGalleryIndex] = useState(0);
  
  const imageGalleryRef = useRef(null);

  useEffect(() => {
    getUserTimezone();
    getTraphistories();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let message = props.socketMsg;
    if (message.data) {
      console.log(" in streaming...........");
      setIsCameraOn(true);
      if (streamImage) {
        URL.revokeObjectURL(streamImage);
      }
      setStreamImage(URL.createObjectURL(new Blob([message.data])));
    } else {
      var res = message;
      if (res.message === "takePicture") {
        let src = "data:image/jpeg;base64," + res.row.image;
        setStreamImage(src);
        setIsShowPicture(true);
        getTraphistories();
      } else if (res.message === "cameraOn") {
        setIsCameraOn(true);
        setIsShowPicture(false);
      } else if (res.message === "cameraOff") {
        setIsCameraOn(false);
      } else if (res.message === "armTrap") {
        console.log("Open trap.....................");
        setIsTrapOpen(true);
        getTraphistories();
      } else if (res.message === "closeTrap") {
        setIsTrapOpen(false);
        getTraphistories();
      } else if (res.message === "buzzerOn") {
        console.log("BuzzerON.....................");
      } else if (res.message === "activateTrap") {
        console.log("activateTrap.....................");
      } else if (res.message === "ledOn") {
        console.log("Led on.....................");
        setIsLEDOn(true);
      } else if (res.message === "ledOff") {
        console.log("Led offffffffff.....................");
        setIsLEDOn(false);
      } else if (res.message === "openBucket") {
        console.log("openBucket.....................");
        setIsBucketOn(true);
      } else if (res.message === "closeBucket") {
        console.log("closeBucket.....................");
        setIsBucketOn(false);
      } else if (res.message === "newEvent") {
        console.log("newEvent.....................");
        getTraphistories();
      } else if (res.message === "statusReq") {
        let ts = res.ts;
        setIsTrapOpen(ts.trap_opened ? false : true);
        setIsLEDOn(ts.led_on ? true : false);
        setIsBucketOn(ts.flap_opened ? true : false);
      }
    }
    // eslint-disable-next-line
  }, [props.socketMsg]);

  const getUserTimezone = () => {
    let user = JSON.parse(window.localStorage.getItem("user"));
    axios
      .post(`${SETTING_SERVER}/getorcreatebyuserid`, {
        userId: user.id,
      })
      .then((data) => {
        if (data.data.success) {
          if ("timeZone" in data.data.setting) {
            if (data.data.setting.timeZone != null) {
              setuserTimezone(data.data.setting.timeZone);
            }
          }
        }
      })
  }

  const onEnterImageModal = () => {
    //imageGalleryRef.current.slideToIndex(imageGalleryIndex);
  };

  const onImageGallerySlide = (currentIndex) => {
    setImageGalleryIndex(currentIndex);
    let dateTime = new Date(traphistories[currentIndex + (currentPage - 1) * 10].createdAt);
    let imageDate = getImageDate(dateTime);
    let eventDescription = getEventDescription(traphistories[currentIndex + (currentPage - 1) * 10].event, props.deviceModel);
    setShowingImageDate(imageDate);
    setShowingImageEvent(eventDescription);
  };

  const getTraphistories = () => {
    let user = JSON.parse(window.localStorage.getItem("user"));
    axios
      .post(`${TRAPHISTORY_SERVER}/getfromdeviceid`, {
        deviceId: props.deviceId,
        userId: user.id,
        cursor: 0,
      })
      .then((data) => {
        if (data.data.success) {
          //let temp = data.data.traphistories.reverse();
          let temp = data.data.traphistories;
          let tempAutoCount = 0; //count of auto trapped
          let tmpImages = [];
          temp.forEach((item) => {
            if (item.event === "auto") {
              tempAutoCount++;
            }

            // append image to images
            if (item.filename) {
              tmpImages.push({
                original: "/" + item.filename,
              });
            }
          });
          setGalleryImages(tmpImages);
          props.updateAutoCount(tempAutoCount);
          const totalRecords = data.data.totalRecords;
          const recordDelta = totalRecords - temp.length;
          const remainingData = Array.from({length: recordDelta}, (_, i) => {
            return {
              deviceId: props.deviceId,
              class: "",
              createdAt: 1704067200000,
            } 
          } );
          temp = temp.concat(remainingData);
          setTraphistories(temp);
          setTotalPages(Math.ceil(data.data.totalRecords / 10));
          setTotalRecords(data.data.totalRecords);
          setCursor(data.data.cursor);
        } else {
          console.log("Unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const actionFormater = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Button
        variant="outline-secondary"
        className="actionBtn"
        onClick={() => {
          deleteTraps([row]);
        }}
      >
        <FaTrash />
      </Button>
    );
  };

  const predictionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return cell;
  };

  const photoFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <img
        alt="mousetrap"
        src={"/" + cell}
        className="picThumb"
        onClick={() => {
          onClickImage(row, rowIndex);
        }}
      />
    );
  };

  const detectionFormatter = (cell, row, rowIndex, formatExtraData) => {
    return row.class.length > 1 ? (
      <DectCanvas
        filename={"/" + row.filename}
        class={row.class}
        box={row.box}
        confidence={row.confidence}
        createdAt={row.createdAt}
        event={row.event}
        deviceModel={props.deviceModel}
      />
    ) : (
      <img
        alt="mousetrap"
        src={"/" + row.filename}
        className="picThumb"
        onClick={() => {
          onClickImage(row, rowIndex);
        }}
      />
    );
  };

  const timeFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (cell === 1704067200000) return "";
    let dbDateTime = new Date(cell);
    let dateTime = getImageDate(dbDateTime);
    return <div>{dateTime}</div>;
  };

  const eventFormatter = (cell, row, rowIndex, formatExtraData) => {
    let eventDescription;
    if (cell in eventDescriptions) {
      eventDescription = eventDescriptions[cell];
    }
    if (props.deviceModel in eventDescriptions) {
      if (cell in eventDescriptions[props.deviceModel]) {
        eventDescription = eventDescriptions[props.deviceModel][cell];
      }
    }
    return eventDescription;
  };

  const columns = props.own
    ? [
        {
          dataField: "createdAt",
          text: "Time",
          formatter: timeFormatter,
          headerStyle: { color: "#F47721", cursor: "pointer" },
          style: { cursor: "pointer" },
          classes: "fullWidth",
          sort: true,
        },
        {
          dataField: "event",
          text: "Event",
          formatter: eventFormatter,
          classes: "fullWidth",
        },
        {
          dataField: "aiPrediction",
          text: "Prediction (GPT4)",
          formatter: predictionFormatter,
          classes: "halfWidth",
          style: { "white-space": "pre", "text-wrap": "wrap" },
        },
        {
          dataField: "filename",
          text: "Picture",
          style: { cursor: "pointer" },
          formatter: photoFormatter,
          classes: "halfWidth",
        },
        {
          dataField: "",
          text: "Actions",
          formatter: actionFormater,
          classes: "fullWidth",
        },
      ]
    : [
        {
          dataField: "createdAt",
          text: "Time",
          formatter: timeFormatter,
          headerStyle: { color: "#F47721", cursor: "pointer" },
          style: { cursor: "pointer" },
          classes: "fullWidth",
          sort: true,
        },
        {
          dataField: "event",
          text: "Event",
          formatter: eventFormatter,
          classes: "fullWidth",
        },
        {
          dataField: "aiPrediction",
          text: "Prediction (GPT4)",
          formatter: predictionFormatter,
          classes: "halfWidth",
          style: { "white-space": "pre", "text-wrap": "wrap" },
        },
        {
          dataField: "filename",
          text: "Picture",
          style: { cursor: "pointer" },
          formatter: photoFormatter,
          classes: "halfWidth",
        },
      ];

  const selectRow = {
    mode: "checkbox",
    bgColor: "#EAF6FC",
    onSelect: (row, isSelect, rowIndex, e) => {
      setSelectedTraps((selectedTraps) => {
        if (isSelect) {
          return [...selectedTraps, row];
        } else {
          let k = selectedTraps.findIndex((temp) => temp.id === row.id);
          return [
            ...selectedTraps.slice(0, k),
            ...selectedTraps.slice(k + 1, selectedTraps.length),
          ];
        }
      });
    },
    onSelectAll: (isSelect, rows, e) => {
      setSelectedTraps((selectedTraps) => {
        if (isSelect) {
          return rows; 
        } else {
          return [];
        }
      });
    },
  };

  const onClickGoLive = (e) => {
    var json = {
      message: e.currentTarget.checked ? "cameraOn" : "cameraOff",
    };
    console.log("''''''''''''''''''''''''''''''''", json);
    sendJsonByWebSocket(getWs(), props.deviceId, json);
  };

  const onClickTakePicture = () => {
    var json = {
      message: "takePicture",
    };
    sendJsonByWebSocket(getWs(), props.deviceId, json);
  };

  const onClickLockTrap = (e) => {
    // setIsTrapOpen(e.currentTarget.checked);
    let command = isTrapOpen ? "closeTrap" : "armTrap";
    var json = {
      message: command,
    };
    sendJsonByWebSocket(getWs(), props.deviceId, json);
    console.log(
      e.currentTarget.checked,
      "---------Lock trap clicked-------",
      isTrapOpen
    );
  };

  const onClickBucket = (e) => {
    let command = isBucketOn ? "closeBucket" : "openBucket";
    var json = {
      message: command,
    };
    sendJsonByWebSocket(getWs(), props.deviceId, json);
  };

  const onClickBuzzer = (e) => {
    var json = {
      message: "buzzerOn",
    };
    sendJsonByWebSocket(getWs(), props.deviceId, json);
    console.log("Buzzer clicked");
  };

  const onClickActiveTrap = (e) => {
    var json = {
      message: "activateTrap",
    };
    sendJsonByWebSocket(getWs(), props.deviceId, json);
    console.log("activateTrap clicked");
  };

  const onClickLed = (e) => {
    let command = isLEDOn ? "ledOff" : "ledOn";
    var json = {
      message: command,
    };
    sendJsonByWebSocket(getWs(), props.deviceId, json);
    console.log("LED offclicked", isLEDOn);
  };

  let disabledSelected = selectedTraps.length < 1 ? true : false;

  const deleteTraps = (traps) => {
    let idArray = [];
    traps.forEach((trap) => {
      idArray.push(trap.id);
    });
    axios
      .post(`${TRAPHISTORY_SERVER}/remove`, {
        id: idArray,
      })
      .then((data) => {
        if (data.data.success) {
          traps.forEach((trap) => {
            setTraphistories((traphistories) => {
              let k = traphistories.findIndex(
                (traphistory) => traphistory.id === trap.id
              );
              if (k > -1) {
                return [
                  ...traphistories.slice(0, k),
                  ...traphistories.slice(k + 1, traphistories.length),
                ];
              } else {
                return traphistories;
              }
            });
          });
        } else {
          console.log("unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const deleteSelectedTraps = () => {
    deleteTraps(selectedTraps);
    setSelectedTraps([]);
  };

  const deleteAllTraps = () => {
    axios
      .post(`${TRAPHISTORY_SERVER}/removebydeviceid`, {
        deviceid: props.deviceId,
      })
      .then((data) => {
        if (data.data.success) {
          setTraphistories([]);
        } else {
          console.log("unsuccess");
        }
      })
      .catch(() => {
        console.log("Oops, request failed!");
      });
  };

  const getImageDate = (dateTime) => {
    return dateTime.toLocaleString('en-US',
                 {
                  month: 'short', 
                  day: 'numeric',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: '2-digit',
                  second: '2-digit',
                  hour12: true,
                  timeZone: userTimezone
                });
  };

  const getEventDescription = (event, deviceModel) => {
    let eventDescription = '';
    if (event in eventDescriptions) {
      eventDescription = eventDescriptions[event];
    }
    if (deviceModel in eventDescriptions) {
      if (event in eventDescriptions[deviceModel]) {
        eventDescription = eventDescriptions[deviceModel][event];
      }
    }
    return eventDescription;
  };

  const onClickImage = (row, rowIndex) => {
    let dateTime = new Date(row.createdAt);
    let imageDate = getImageDate(dateTime);
    let eventDescription = getEventDescription(row.event, props.deviceModel);
    setShowingImageDate(imageDate);
    setShowingImageEvent(eventDescription);
    //setImageSrc(row.filename);
    setImageSrc("/" + row.filename);
    setShowImageModal(true);
    // get row index from filename
    let _rowIndex = 0;
    for (let i = 0; i < galleryImages.length; i++) {
      if (galleryImages[i].original === "/" + row.filename) {
        _rowIndex = i;
        break;
      }
    }
    setImageGalleryIndex(_rowIndex);
  };

  const onCloseImageModal = () => {
    setShowingImageDate("");
    setImageSrc("");
    setShowingImageEvent("");
    setShowImageModal(false);
  };

  return (
    <div className="sub_content">
      <div className="sub_title">Camera control</div>
      <div className="sub_body">
        <div className="row">
          <ButtonGroup toggle size="sm" className="firstButtonGroup">
            <ToggleButton
              variant={isCameraOn ? "success" : "outline-dark"}
              type="checkbox"
              className="controlBtns"
              checked={isCameraOn}
              onChange={onClickGoLive}
            >
              {isCameraOn ? (
                <Icon icon={eyeIcon} width="19" />
              ) : (
                <Icon icon={eyeOff} width="19" />
              )}
              {isCameraOn ? " Online" : " Go live"}
            </ToggleButton>
            <Button
              variant="outline-dark"
              className="controlBtns"
              onClick={onClickTakePicture}
            >
              <Icon icon={cameraIcon} width="19" /> Take picture
            </Button>
          </ButtonGroup>

          <ToggleButtonGroup
            type="checkbox"
            size="sm"
            className="secondButtonGroup"
          >
            {props.deviceModel === "A1" || props.deviceModel === "A2" || props.deviceModel === "F1" || props.deviceModel === "F2" ? (
              <ToggleButton
                variant={isTrapOpen ? "success" : "outline-dark"}
                type="checkbox"
                checked={isTrapOpen}
                className="controlBtns"
                onChange={onClickLockTrap}
              >
                {/* {isTrapOpen ? <FaLock /> : <FaLockOpen />} */}
                {isTrapOpen ? (
                  <Icon icon={lockIcon} width="19" />
                ) : (
                  <Icon icon={lockOpenVariant} width="19" />
                )}
                {isTrapOpen ? " Close Trap" : " Open Trap"}
              </ToggleButton>
            ) : null}
            {props.deviceModel === "A2" || props.deviceModel === "F2" ? (
              <ToggleButton
                variant={isBucketOn ? "success" : "outline-dark"}
                type="checkbox"
                checked={isBucketOn}
                className="controlBtns"
                onChange={onClickBucket}
              >
                <div>
                  {isBucketOn ? (
                    <Icon icon={pailOffOutline} width="19" />
                  ) : (
                    <Icon icon={pailOutline} width="19" />
                  )}
                  {isBucketOn ? " Close bucket door" : " Open bucket door"}
                </div>
              </ToggleButton>
            ) : null}
            {props.deviceModel === "A1" || props.deviceModel === "F1" ? (
              <Button
                variant="outline-dark"
                type="checkbox"
                onClick={onClickBuzzer}
              >
                <Icon icon={volumeVibrate} width="19" /> Turn Buzzer on
              </Button>
            ) : null}
            {props.deviceModel === "B1" ? (
              <Button
                variant="outline-dark"
                type="checkbox"
                onClick={onClickActiveTrap}
              >
                <Icon icon={lockOpenVariant} width="19" /> Activate Trap
              </Button>
            ) : null}
            <ToggleButton
              variant={isLEDOn ? "success" : "outline-dark"}
              type="checkbox"
              className="controlBtns"
              checked={isLEDOn}
              onChange={onClickLed}
            >
              {isLEDOn ? (
                <Icon icon={ledVariantOff} width="19" />
              ) : (
                <Icon icon={ledOn} width="19" />
              )}
              {isLEDOn ? " Turn LED off" : " Turn LED on"}
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        {isCameraOn || isShowPicture ? (
          <Container
            fluid
            style={{ border: "1px solid #E0E0E0", marginTop: "20px" }}
          >
            <div className="pictureStream">
              <img alt="mousetrap" src={streamImage} className="livePicture" />
            </div>
          </Container>
        ) : (
          <Container
            fluid
            style={{
              border: "1px solid #E0E0E0",
              marginTop: "20px",
              height: "400px",
              paddingTop: "150px",
            }}
          >
            <Row className="justify-content-md-center">
              <Col md="2" style={{ textAlign: "center" }}>
                <Icon icon={cameraOff} color="#BDBDBD" width="48" />
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col md="2" style={{ textAlign: "center" }}>
                <span style={{ fontSize: "18px" }}>Camera is off line</span>
              </Col>
            </Row>
          </Container>
        )}
        <div className="sub_title">Device Log</div>
        <div className="sub_body">
          {!props.own ? null : (
            <Form.Group>
              <ButtonGroup size="sm">
                <Button
                  variant="outline-dark"
                  className="controlBtn"
                  disabled={disabledSelected}
                  onClick={deleteSelectedTraps}
                >
                  <FaTrash />
                  Delete selected
                </Button>
                <Button
                  variant="outline-dark"
                  className="controlBtn"
                  onClick={deleteAllTraps}
                >
                  <FaTrash />
                  Delete all
                </Button>
              </ButtonGroup>
            </Form.Group>
          )}
          {props.own ? (
            <BootstrapTable
              keyField="id"
              classes="tableWithMobile"
              data={traphistories}
              columns={columns}
              bordered={false}
              selectRow={selectRow}
              pagination={paginationFactory(pnoptions)}
            />
          ) : (
            <BootstrapTable
              keyField="id"
              classes="tableWithMobile"
              data={traphistories}
              columns={columns}
              bordered={false}
              striped={true}
              pagination={paginationFactory(pnoptions)}
            />
          )}
          <Modal show={showImageModal} onHide={onCloseImageModal} onEnter={onEnterImageModal} size="lg" centered>
            <Modal.Header closeButton style={{ borderBottom: "none" }}>
              <Modal.Title>Device log</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: "0" }}>
              <Container>
                <Row>
                  <Col className="text-center text-secondary">
                    {showingImageDate}
                  </Col>
                </Row>
                <Row>
                  <Col className="text-center">
                    {showingImageEvent}
                  </Col>
                </Row>
                <Row>
                  <Col
                    style={{
                      padding: "0px",
                      marginTop: "10px",
                      marginBottom: "50px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <ImageGallery
                      ref={imageGalleryRef}
                      items={galleryImages}
                      showPlayButton={false}
                      lazyLoad={true}
                      onSlide={onImageGallerySlide}
                      startIndex={imageGalleryIndex}
                    />
                  </Col>
                </Row>
              </Container>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
};